import { useDatasetContext } from '@/contexts/DatasetContext';
import { QuiBox, QuiButton, QuiIcon, QuiIconEnum } from '@tonicai/ui-quinine';
import { useMemo, useRef, useState } from 'react';
import { getPiiTypeInfo } from './utils';
import { PiiTypeEnum } from '@/types';

export default function UndetectedEntityTypes() {
    const { dataset } = useDatasetContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    const undetectedEntities = useMemo(() => {
        const allEntities = Object.keys(dataset.datasetGeneratorMetadata);
        const detectedEntites = Object.keys(dataset.generatorSetup);

        // TODO: pull from a BE endpoint rather than hardcoding these. See:
        // https://tonic-ai.slack.com/archives/C07CQ8HUVPG/p1724708006317639
        const deprecatedEntities = ['PROJECT_NAME', 'USERNAME', 'PASSWORD', 'US_DRIVER_LICENSE'];

        return allEntities
            .filter((entity) => !detectedEntites.includes(entity) && !deprecatedEntities.includes(entity))
            .map((entity) => getPiiTypeInfo(entity as PiiTypeEnum));
    }, [dataset]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="xs" border="white-600" borderRadius="md">
            <QuiBox padding="sm">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        <QuiIcon icon={QuiIconEnum.Minus} />
                        <div ref={scrollRef}>
                            <div className={'pii-type-label'}>Entity types not found</div>
                            <div className={'pii-type-description'}>Textual did not find these entity types in the files</div>
                        </div>
                    </QuiBox>
                    <QuiButton
                        iconRight={isExpanded ? QuiIconEnum.ChevronUp : QuiIconEnum.ChevronDown}
                        size="sm"
                        variant="outline-default"
                        onClick={() => {
                            setIsExpanded((current) => !current);
                            scrollRef?.current?.scrollIntoView({
                                block: 'center',
                                inline: 'center',
                            });
                        }}
                    />
                </QuiBox>
            </QuiBox>
            {isExpanded &&
                undetectedEntities.map((entity) => {
                    return (
                        <QuiBox padding="none md" key={entity.label}>
                            <QuiBox padding="sm" borderTop="white-600">
                                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 10,
                                        }}
                                    >
                                        <QuiIcon icon={entity.icon ?? 'help-circle'} />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <span className={'pii-type-label'}>{entity.label}</span>
                                            <span className={'pii-type-description'}>{entity.description}</span>
                                        </div>
                                    </div>
                                </QuiBox>
                            </QuiBox>
                        </QuiBox>
                    );
                })}
        </QuiBox>
    );
}
