import { ParseJobFileParseJobs } from '@/components/ParseJobFileParseJobs/ParseJobFileParseJobs';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';
import { Tabs } from '@/components/Tabs/Tabs';
import { PipelineEntityConfigForm } from '@/pages/ParseJob/PipelineEntityConfigForm';
import { QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { FilesTableLocal } from '../FileParseJob/FilesTableLocal';

export function FileUploadPipeline() {
    const { parseJobConfig } = usePipelineContext();
    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle icon="list" title="Overview" />

            <Tabs.Container defaultTab="files">
                <Tabs.TabTriggers>
                    <Tabs.TabTrigger data-test="pipeline-files-tab" icon="inbox" id="files">
                        Files
                    </Tabs.TabTrigger>
                    <Tabs.TabTrigger data-test="generator-config" icon="eye" id="generator-config">
                        Generator Config
                    </Tabs.TabTrigger>
                </Tabs.TabTriggers>
                <Tabs.TabContent id="files">
                    <FilesTableLocal />
                </Tabs.TabContent>
                <Tabs.TabContent id="runs">
                    <ParseJobFileParseJobs />
                </Tabs.TabContent>
                <Tabs.TabContent id="generator-config">
                    <PipelineEntityConfigForm disabled={!parseJobConfig.synthesizeFiles} />
                </Tabs.TabContent>
            </Tabs.Container>
        </QuiFlexBoxColumn>
    );
}
