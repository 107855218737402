import { Auth } from '../../components/Auth/Auth';
import { useAtomValue } from 'jotai';
import { onboardingSurveyIsCompletedAtom } from '../../stores/auth';
import { OnboardingSurveyDialog } from '../Home/OnboardingSurveyDialog';

export function OnboardingSurvey() {
    const isSurveyCompleted = useAtomValue(onboardingSurveyIsCompletedAtom);

    return (
        <Auth style={{ maxWidth: '580px' }}>
            <OnboardingSurveyDialog shouldShowSurvey={!isSurveyCompleted} />
        </Auth>
    );
}
