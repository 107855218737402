export enum SolarEnvironmentVariable {
    TEMP_IS_ENTITY_TYPE_REGEX_ENABLED = 'TEMP_IS_ENTITY_TYPE_REGEX_ENABLED',
    IsHostedProd = 'SOLAR_IS_HOSTED_PROD',
    OcrEnabled = 'OCR_ENABLED',
    SolarSharepointUploadEnabled = 'SOLAR_SHAREPOINT_UPLOAD_ENABLED',
    HAS_OPENAI_API_KEY = 'HAS_OPENAI_API_KEY',
    ARE_AWS_CREDS_AVAILABLE = 'AWS_CREDS_AVAILABLE',
}

export type SolarEnvironmentVariables = Record<SolarEnvironmentVariable, unknown>;
