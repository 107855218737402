import { TextualUseCaseField } from '@/pages/Home/TextualUseCaseField';
import { postOnboardingSurveyResults } from '@/stores';
import { OnboardingSurveyData } from '@/types';
import { requiredString } from '@/validation';
import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiForm, QuiSubmitButton, QuiTextField } from '@tonicai/ui-quinine';
import { MutableState, Mutator } from 'final-form';
import { useFormState } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

interface OnboardingSurveyDialogProps {
    shouldShowSurvey: boolean;
}

const INITIAL_VALUES: OnboardingSurveyData = {
    firstName: '',
    lastName: '',
    title: '',
    organization: '',
    useCases: [],
    otherUseCase: '',
};

export function OnboardingSurveyDialog({ shouldShowSurvey }: OnboardingSurveyDialogProps) {
    const navigate = useNavigate();

    const navigateToHomePage = () => {
        navigate('/');
    };

    const submitForm = (values: OnboardingSurveyData) => {
        return postOnboardingSurveyResults(values).finally(() => {
            navigateToHomePage();
        });
    };

    const setFieldTouched: Mutator<OnboardingSurveyData, Partial<OnboardingSurveyData>> = (
        [field]: [string],
        state: MutableState<OnboardingSurveyData, unknown>
    ) => {
        state.fields[field].touched = true;
    };

    return (
        <QuiFlexBoxColumn padding="md xs" gap="md" style={{ width: '100%' }}>
            <QuiBox>
                <QuiBox text="text-lg" weight="bold">
                    👋 Welcome to Textual!
                </QuiBox>
                <QuiBox text="text-md">
                    {shouldShowSurvey
                        ? 'A few things before you get started.'
                        : 'We have already collected your survey information, please click below to get back to Textual.'}
                </QuiBox>
            </QuiBox>
            {shouldShowSurvey ? (
                <QuiForm<OnboardingSurveyData> onSubmit={submitForm} initialValues={INITIAL_VALUES} mutators={{ setFieldTouched }}>
                    <QuiBox display="flex" gap={'lg'} flexDirection="column">
                        <QuiBox display="flex" gap={'sm'} flexDirection="column">
                            <QuiBox display="flex" flexDirection="row" justifyContent="space-between" gap="md">
                                <QuiTextField name={'firstName'} label={'First Name'} style={{ width: '100%' }} validate={requiredString} />
                                <QuiTextField name={'lastName'} label={'Last Name'} style={{ width: '100%' }} validate={requiredString} />
                            </QuiBox>
                            <QuiBox display="flex" flexDirection="row" justifyContent="space-between" gap="md">
                                <QuiTextField name={'organization'} label={'Organization'} style={{ width: '100%' }} validate={requiredString} />
                                <QuiTextField name={'title'} label={'Title'} style={{ width: '100%' }} validate={requiredString} />
                            </QuiBox>
                            <TextualUseCaseField
                                name={'useCases'}
                                label={'What are you looking to use Tonic Textual to accomplish?'}
                                helperText={'Select all options that apply:'}
                                otherFieldName="otherUseCase"
                            />
                        </QuiBox>
                        <SubmitButton />
                    </QuiBox>
                </QuiForm>
            ) : (
                <QuiButton onClick={navigateToHomePage} variant={'brand-purple'}>
                    Go to Textual
                </QuiButton>
            )}
        </QuiFlexBoxColumn>
    );
}

function SubmitButton() {
    const { submitting, hasValidationErrors } = useFormState(); // Use useFormState to get form state

    return (
        <QuiSubmitButton
            style={{ width: 'fit-content' }}
            variant={'brand-purple'}
            disabled={submitting || hasValidationErrors} // Disable based on form state
        >
            Get Started
        </QuiSubmitButton>
    );
}
