import { QuiBox, QuiFlexBoxColumn, QuiForm, QuiSubmitButton, QuiTooltip, useQuiToasts } from '@tonicai/ui-quinine';
import { DateTimeMetadata, HipaaAddressMetadata, NameMetadata, PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { GeneratorFields } from '@/components/GeneratorFields/GeneratorFields';
import { Mutator } from 'final-form';
import mutators from 'final-form-arrays';
import { FormError } from '@/components/FormError/FormError';
import { client } from '@/services/HTTPClient';
import { EndpointGuard, EndpointGuardComponentProps } from '@/components/EndpointGuard/EndpointGuard';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';

type PipelineGeneratorMetadata = Partial<{
    [PiiTypeEnum.DATE_TIME]: DateTimeMetadata;
    [PiiTypeEnum.LOCATION]: HipaaAddressMetadata;
    [PiiTypeEnum.PERSON]: NameMetadata;

    // These might not be necessary, as datasets doesn't have them
    [PiiTypeEnum.LOCATION_ADDRESS]: HipaaAddressMetadata;
    [PiiTypeEnum.LOCATION_ZIP]: HipaaAddressMetadata;
    [PiiTypeEnum.LOCATION_STATE]: HipaaAddressMetadata;
    [PiiTypeEnum.LOCATION_CITY]: HipaaAddressMetadata;
    [PiiTypeEnum.NAME_GIVEN]: NameMetadata;
    [PiiTypeEnum.NAME_FAMILY]: NameMetadata;
}>;

const formMutators: Record<string, Mutator<PipelineConfigGeneratorModel>> = {
    ...mutators,
};

// const INIITIAL_VALUES_DEFAULT: PipelineConfigGeneratorModel = {
//     generatorSetup: Object.entries(PiiTypeEnum).reduce((acc, [, piiType]) => {
//         return {
//             ...acc,
//             [piiType]: 'Off',
//         };
//     }, {}) as unknown as Record<PiiTypeEnum, PiiTypeGeneratorState>,
//     generatorMetadata: {},
// };

type PipelineConfigGeneratorModel = {
    generatorSetup: Record<PiiTypeEnum, PiiTypeGeneratorState>;
    generatorMetadata: Partial<PipelineGeneratorMetadata>;
    disabled?: boolean;
};

function PipelineEntityConfigFormContent({ data, disabled }: EndpointGuardComponentProps<PipelineConfigGeneratorModel>) {
    disabled = disabled ?? false;
    const { parseJobConfigId } = usePipelineContext();
    const addToast = useQuiToasts();

    return (
        <QuiForm<PipelineConfigGeneratorModel>
            initialValues={data}
            mutators={formMutators}
            onSubmit={(values) => {
                client
                    .post(`/api/parsejobconfig/${parseJobConfigId}/entity-config`, values)
                    .then(() => {
                        addToast({
                            title: 'Config updated successfully',
                            variant: 'positive',
                        });
                    })
                    .catch(() => {
                        addToast({
                            title: 'Unable to update config',
                            variant: 'destructive',
                        });
                    });
            }}
        >
            <QuiFlexBoxColumn gap="lg">
                <QuiBox display="flex" justifyContent="space-between">
                    <QuiBox maxWidth="tablet" color="text-secondary" text="text-sm">
                        Configure which data is redacted, synthesized, or ignored when outputting files to the original format. You'll still get a
                        JSON file with all of the metadata.
                        <br />
                        {disabled && 'Requires pipeline synthesis to be enabled.'}
                    </QuiBox>

                    <QuiTooltip content="Generators are only applied when pipeline synthesis is enabled" disabled={!disabled}>
                        <QuiSubmitButton disabled={disabled} variant="brand-purple">
                            Save Changes
                        </QuiSubmitButton>
                    </QuiTooltip>
                </QuiBox>
                <FormError />
                <GeneratorFields generatorMetadataName="generatorMetadata" generatorStatesName="generatorSetup" disabled={disabled} />
            </QuiFlexBoxColumn>
        </QuiForm>
    );
}

export function PipelineEntityConfigForm({ disabled }: { disabled?: boolean }) {
    const { parseJobConfigId } = usePipelineContext();

    return (
        <EndpointGuard<PipelineConfigGeneratorModel>
            endpoint={`/api/parsejobconfig/${parseJobConfigId}/entity-config`}
            Component={PipelineEntityConfigFormContent}
            errorMessage="Error loading config"
            notFoundMessage="Config not found"
            disabled={disabled}
        />
    );
}
